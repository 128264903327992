// User Route
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const CHANGE_PASSWORD = '/changePassword'
export const UPDATE_PROFILE = '/profileUpdate'
export const ME = '/me'


// users route
export const USERS = '/getAllUsers_p'
export const CREATE_USER = '/createUser'
export const SINGLE_USER_INFO = '/getUser'
export const UPDATE_USER = '/updateUser'

// roles route
export const ROLES = '/role/getAllRoles'
export const ROLES_P = '/role/getAllRoles_p'
export const ROLES_ALL = '/role/getAllRoles'
export const ROLE_DELETE = '/role/deleteRole'
export const SINGLE_ROLE_INFO = '/role/getRole'
export const UPDATE_ROLE = '/role/updateRole'
export const CREATE_ROLE = '/role/createRole'

// permission routes
export const PERMISSION_ALL = '/permission/getAllpermissions'
export const PERMISSION_P = '/permission/getAllPermissions_p'
export const CREATE_PERMISSION = '/permission/createPermission'
export const SINGLE_PERMISSION_INFO = '/permission/getPermission'
export const UPDATE_PERMISSION = '/permission/updatePermission'

// permission modules routes
export const MODULE_ALL = '/module/getAllModules'



// Audit log
export const CREATE_AUDIT_LOG = '/audit-log/createAuditLog'
export const ALL_AUDIT_LOG_P = '/audit-log/getAllAuditLog_p'
export const USER_LIST = '/audit-log/getAllUserList'


// ===============Configuration routes==============
// ===============Configuration routes==============

// -----------designation routes---------------
export const DESIGNATION_FILTER_LIST = '/designation/filter-list'
export const DESIGNATION_P = '/designation/designation-list-paginate'
export const CREATE_DESIGNATION = '/designation/designation-create'
export const SINGLE_DESIGNATION_INFO = '/designation/single-designation-data'
export const UPDATE_DESIGNATION = '/designation/designation-update'


// -----------Business KAM routes routes---------------
export const BUSINESS_KAM_FILTER_LIST = '/business-kam/filter-list'
export const BUSINESS_KAM_P = '/business-kam/business-kam-list-paginate'
export const CREATE_BUSINESS_KAM = '/business-kam/business-kam-create'
export const SINGLE_BUSINESS_KAM_INFO = '/business-kam/single-business-kam-data'
export const UPDATE_BUSINESS_KAM = '/business-kam/business-kam-update'

// -----------company routes---------------
export const COMPANY_FILTER_LIST = '/company/filter-list'
export const COMPANY_P = '/company/company-list-paginate'
export const CREATE_COMPANY = '/company/company-create'
export const SINGLE_COMPANY_INFO = '/company/single-company-data'
export const UPDATE_COMPANY = '/company/company-update'

// -----------Client KAM routes routes---------------
export const CLIENT_KAM_FILTER_LIST = '/client-kam/filter-list'
export const CLIENT_KAM_P = '/client-kam/client-kam-list-paginate'
export const CREATE_CLIENT_KAM = '/client-kam/client-kam-create'
export const SINGLE_CLIENT_KAM_INFO = '/client-kam/single-client-kam-data'
export const UPDATE_CLIENT_KAM = '/client-kam/client-kam-update'

// -----------Client routes routes---------------
export const CLIENT_FILTER_LIST = '/client/filter-list'
export const CLIENT_P = '/client/client-list-paginate'
export const CREATE_CLIENT = '/client/client-create'
export const SINGLE_CLIENT_INFO = '/client/single-client-data'
export const UPDATE_CLIENT = '/client/client-update'

// -----------Offer routes routes---------------
export const OFFER_FILTER_LIST = '/offer/filter-list'
export const OFFER_P = '/offer/offer-list-paginate'
export const CREATE_OFFER = '/offer/offer-create'
export const SINGLE_OFFER_INFO = '/offer/single-offer-data'
export const UPDATE_OFFER = '/offer/offer-update'
export const OFFERS = '/offer/offers' // this is for public api Documentation