import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux'
import Validation from '../../../../components/Forms/Validation.js';
import { getCall, putCall, postCall } from '../../../../api/apiService.js'
import { BUSINESS_KAM_FILTER_LIST, BUSINESS_KAM_P, CREATE_BUSINESS_KAM, UPDATE_BUSINESS_KAM, SINGLE_BUSINESS_KAM_INFO } from '../../../../api/apiPath.js'
import Paginate from '../../../../components/Datatable/Paginate.js'
import { toast } from 'react-toastify'
import Svgediticoncomponent from '../../../../components/Icons/Svgediticoncomponent.js'
import { SET_BREADCRUMB_DATA, SET_USER_DATA } from '../../../../redux/action.js'
import { Link } from 'react-router-dom';
import { permissionsResets, transformSortObject } from '../../../../components/Helpers/CommonHelpers.js'
import Submitbutton from '../../../../components/Buttons/Submitbutton.js';
import Select from 'react-select'
import Sorting from '../../../../components/Datatable/Sorting.js';

function BusinessKAM(props) {

    const breadcrumb = {
        pageTitle: 'Business KAM',
        currentPath: '/business-kam',
        layers: [
            {
                title: 'Dashboard',
                link: '/'
            },
            {
                title: 'Configuration'
            },
            {
                title: 'Business KAM',
                default: 1
            }
        ]
    }

    const formInitial = {
        filter: {
            designation_selected_option: null,
            designation_id: null,
            business_kam_selected_option: null,
            business_kam_id: null,
            user_selected_option: null,
            user_id: null
        },
        form: {
            data: {
                id: '',
                name: '',
                designation_id: '',
                user_id: '',
                designation_selected_option: null,
                user_selected_option: null,
            },
            errors: null
        },
        table: {
            data: null,
            paginator: null,
            loading: false,
            empty: true,
            sort: {
                column: null,
                table: null,
                order: null,
            }
        },
        designation_list: null,
        user_list: null,
        business_kam_list: null,
    }

    const [formData, setFormData] = useState(formInitial)

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            form: {
                ...prev?.form,
                data: {
                    ...prev?.form?.data,
                    [e.target.name]: e.target.value
                }
            }
        }))
    }



    const getTableData = async (e, filteredData = { ...formData?.filter, page: 1 }, sort = null) => {
        setFormData((prev) => ({ ...prev, table: { ...prev?.table, sort, data: null, paginator: null, loading: true, empty: true } }))
        if (e && e.preventDefault) e.preventDefault();
        const transformedSort = transformSortObject(sort);
        const request = { ...filteredData, page: filteredData?.page, ...transformedSort }
        const response = await getCall(BUSINESS_KAM_P, request, props.user.access_token)
        if (response?.code === 200) {
            let empty = (response?.data?.data?.length == 0) ? true : false
            setFormData((prev) => ({ ...prev, table: { ...prev.table, data: response?.data?.data, paginator: response?.data?.paginator, loading: false, empty } }))
        } else {
            toast.error(response?.message?.[0])
            setFormData((prev) => ({ ...prev, table: { ...prev.table, data: null, paginator: null, loading: false, empty: false } }))
        }
    }


    useEffect(() => {
        permissionsResets(props)
        props.setPageBreadcrumb(breadcrumb)

        getTableData()
        getFilter()
    }, [])



    const handleSubmit = async (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        const request = { ...formData?.form?.data, id: formData?.form?.data?.id }
        let response;
        if (formData?.form?.data?.id) {
            response = await postCall(UPDATE_BUSINESS_KAM, request, props.user.access_token)
        }
        else {
            response = await postCall(CREATE_BUSINESS_KAM, request, props.user.access_token)
        }
        if (response?.code === 200) {
            getTableData(null, { ...formData?.filter, page: formData?.table?.paginator?.current_page })
            setFormData((prev) => ({ ...prev, form: formInitial?.form }))
            toast.success(response?.message?.[0])
            closeFormModal()
        } else {
            toast.error(response?.message?.[0])
        }
    }

    const updateModalProcess = async (id) => {
        const response = await getCall(`${SINGLE_BUSINESS_KAM_INFO}/${id}`, {}, props.user.access_token)
        if (response?.code === 200) {
            setFormData((prev) => ({ ...prev, form: { ...prev?.form, data: {...response?.data, designation_selected_option: {label: response?.data?.designation?.designation, value:response?.data?.designation_id }, user_selected_option: {label: response?.data?.user?.name+' ('+response?.data?.user?.email+')', value: response?.data?.user_id}} } }))
        }
    }


    const closeFormModal = () => {
        let modalclosebtn = document.getElementById('modalclosebtn')
        modalclosebtn.click();
    }

    const formClear = () => {
        setFormData((prev) => ({ ...prev, form: { ...formInitial?.form } }))
    }

    const filterClear = async() => {
        setFormData((prev) => ({ ...prev, filter: { ...formInitial?.filter } }))
        getTableData(null, { ...formInitial?.filter })
    }

    // filter process
    const getFilter = async () => {
        let response = await getCall(BUSINESS_KAM_FILTER_LIST, {}, props.user.access_token)
        if (response?.code === 200) {
            setFormData((prev) => ({ ...prev, designation_list: response?.data?.filter?.designation, business_kam_list: response?.data?.filter?.business_kam, user_list: response?.data?.filter?.user }))
        } else {
        }
    }

    const handleSortChange = (column, table, order) => {
        setFormData((prev) => ({ ...prev, table: { ...prev.table, sort: { column, table, order } } }))
        getTableData(null, { ...formData?.filter, page: 1 }, { column, table, order })
    };

    return (
        <Fragment>

            <div className="card">

                <div className="card-header pt-5">
                    <form className="w-100" onSubmit={getTableData} >
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >Business KAM:</label>
                                <Select options={formData?.business_kam_list} value={formData?.filter?.business_kam_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, business_kam_selected_option: option, business_kam_id: option?.value } }))
                                    }
                                    isClearable placeholder="Select Business KAM" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >Designation:</label>
                                <Select options={formData?.designation_list} value={formData?.filter?.designation_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, designation_selected_option: option, designation_id: option?.value } }))
                                    }
                                    isClearable placeholder="Select Designation" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >User:</label>
                                <Select options={formData?.user_list} value={formData?.filter?.user_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, user_selected_option: option, user_id: option?.value } }))
                                    }
                                    isClearable placeholder="Select User" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5 my-auto">
                                <Submitbutton />

                                <button type="button" className="btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger" onClick={filterClear}>Reset</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="card-body  py-5 px-2">
                    {
                        props.permissions.includes('designation create') ?
                            <a className="btn btn-sm btn-primary ms-1 mb-2" data-bs-toggle="modal" data-bs-target="#saveConfirmationModal" href="#0" onClick={formClear}>
                                <i className="icofont icofont-ui-add me-1"></i>
                                Create New Business KAM
                            </a>
                            :
                            null
                    }

                    {
                        formData?.table?.loading || formData?.table?.empty ?
                            <div className="row col-12" style={{ marginTop: "20px" }}>
                                {
                                    formData?.table?.loading ?
                                        <div className="spinner-border text-primary mx-auto" style={{ width: "70px", height: "70px" }} alt="Loading..." ></div>
                                        : null
                                }
                                {
                                    !formData?.table?.loading && formData?.table?.empty ?
                                        <div style={{ display: "flex",  justifyContent: "center", alignItems: "center" }}>
                                            <span className="badge badge-light-danger badge-lg" >No Data Found!</span>
                                        </div>
                                        : null
                                }
                            </div> : null
                    }


                    {
                        formData?.table?.data?.length > 0 ?
                            <Fragment>

                                <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                                    <thead>
                                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                            <th>Serial</th>
                                            <th>Name <Sorting column="name" order={formData?.table?.sort?.column == 'name' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Designation <Sorting table="designation" column="designation.designation" order={formData?.table?.sort?.column == 'designation.designation' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Email <Sorting table="users" column="users.email" order={formData?.table?.sort?.column == 'users.email' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            formData?.table?.data?.map((row, i) => {
                                                return (
                                                    <tr key={'table-row-' + i}>
                                                        <td>{formData?.table?.paginator?.current_page > 1 ? ((formData?.table?.paginator?.current_page - 1) * formData?.table?.paginator?.record_per_page) + i + 1 : i + 1}</td>
                                                        <td>{row?.name}</td>
                                                        <td>{row?.designation?.designation}</td>
                                                        <td>{row?.user?.email}</td>
                                                        <td>
                                                            {
                                                                props.permissions.includes('business kam update') ?
                                                                    <div className="form-inline" >
                                                                        <Link role="button" data-bs-toggle="modal" data-bs-target="#saveConfirmationModal" title="Edit Record?" to="0#" className="btn btn-icon btn-active-light-primary w-30px h-30px me-3"
                                                                            onClick={() => updateModalProcess(row.id)}
                                                                        >
                                                                            <span className="svg-icon svg-icon-3"><Svgediticoncomponent /></span>
                                                                        </Link>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                            </Fragment>
                            : null
                    }
                    {
                        formData?.table?.paginator?.total_pages > 1 ?
                            <Paginate paginator={formData?.table?.paginator} pagechanged={(page) => getTableData(null, { ...formData?.filter, page })} /> : null
                    }
                </div>
            </div>


            <div className="modal fade modal-backdrop-static" id="saveConfirmationModal" tabIndex="-1" aria-labelledby="saveConfirmationModal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header  py-4">
                            <p className="modal-title text-center text-dark fw-bolder d-block fs-3" id="saveConfirmationModal" style={{ flex: "auto" }}>{formData?.id ? 'Update' : 'Create New'} Business KAM</p>
                            <div className="btn btn-icon btn-sm btn-active-light-danger ms-2" data-bs-dismiss="modal" aria-label="Close" onClick={formClear}>
                                <i className="icofont icofont-ui-close me-1"></i>
                            </div>
                        </div>
                        <div className="modal-body pt-0 mt-0" >
                            <form className="form-horizontal" onSubmit={handleSubmit} >
                                <div>
                                    <input type="number" className="form-control form-control-sm" id="id" name="id" value={formData?.form?.data?.id} onChange={handleChange} readOnly hidden style={{ heigh: "0", width: "0" }} />

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Name<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control form-control-sm form-control-solid" name="name" placeholder="Name" value={formData?.form?.data?.name} onChange={handleChange} required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Designation<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <Select options={formData?.designation_list} value={formData?.form?.data?.designation_selected_option}
                                                    onChange={(option) =>
                                                        setFormData((prev) => ({ ...prev, form: { ...formData?.form, data: { ...formData?.form?.data, designation_selected_option: option, designation_id: option?.value } } }))
                                                    }
                                                    isClearable placeholder="Select Designation" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">User<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <Select options={formData?.user_list} value={formData?.form?.data?.user_selected_option}
                                                    onChange={(option) =>
                                                        setFormData((prev) => ({ ...prev, form: { ...formData?.form, data: { ...formData?.form?.data, user_selected_option: option, user_id: option?.value } } }))
                                                    }
                                                    isClearable placeholder="Select User" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-footer pb-0">
                                        <button type="button" className="btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger" data-bs-dismiss="modal" onClick={formClear} id="modalclosebtn">Cancel</button>
                                        <button type="submit" className="btn btn-sm btn-primary" id="formSubmit">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}



const mapStateToProps = (state) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions
});

const mapDispatchToProps = (dispatch) => ({
    setPageBreadcrumb: (data) => dispatch(SET_BREADCRUMB_DATA(data)),
    me: (data) => dispatch(SET_USER_DATA(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BusinessKAM));

