import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux'
import Validation from '../../../../components/Forms/Validation.js';
import { getCall, postCall } from '../../../../api/apiService.js'
import { OFFER_FILTER_LIST, OFFER_P, CREATE_OFFER, UPDATE_OFFER, SINGLE_OFFER_INFO } from '../../../../api/apiPath.js'
import Paginate from '../../../../components/Datatable/Paginate.js'
import { toast } from 'react-toastify'
import Svgediticoncomponent from '../../../../components/Icons/Svgediticoncomponent.js'
import { SET_BREADCRUMB_DATA, SET_USER_DATA } from '../../../../redux/action.js'
import { Link } from 'react-router-dom';
import { YMDHMSToJsDate, jsDateToYMDHMS, permissionsResets, transformSortObject } from '../../../../components/Helpers/CommonHelpers.js'
import Submitbutton from '../../../../components/Buttons/Submitbutton.js';
import Select from 'react-select'
import Sorting from '../../../../components/Datatable/Sorting.js';
import Badge from '../../../../components/Badges/Badge.js';
import DatePicker from "react-datepicker";

function Offer(props) {

    const breadcrumb = {
        pageTitle: 'Offer',
        currentPath: '/offer',
        layers: [
            {
                title: 'Dashboard',
                link: '/'
            },
            {
                title: 'Offer',
            },
            {
                title: 'Offer',
                default: 1
            }
        ]
    }

    const formInitial = {
        filter: {
            offer_title_en_selected_option: '',
            offer_title_en: '',
            offer_title_bn_selected_option: '',
            offer_title_bn: '',
            offer_type_selected_option: '',
            offer_type_id: '',
            operator_selected_option: '',
            operator_id: '',
            connection_type_selected_option: '',
            connection_type_id: '',
            amount_selected_option: '',
            amount: '',
            offer_validity_en_selected_option: '',
            offer_validity_en: '',
            offer_validity_bn_selected_option: '',
            offer_validity_bn: '',
            status_selected_option: '',
            status: '',
        },
        form: {
            data: {
                id: '',
                offer_title_en_selected_option: '',
                offer_title_en: '',
                offer_title_bn_selected_option: '',
                offer_title_bn: '',
                offer_type_selected_option: '',
                offer_type_id: '',
                operator_selected_option: '',
                operator_id: '',
                connection_type_selected_option: '',
                connection_type_id: '',
                amount_selected_option: '',
                amount: '',
                offer_validity_en_selected_option: '',
                offer_validity_en: '',
                offer_validity_bn_selected_option: '',
                offer_validity_bn: '',
                offer_start: '',
                offer_start_formatted: '',
                offer_expire: '',
                offer_expire_formatted: '',
                status_selected_option: '',
                status: '',
                offer_url: '',
                offer_validity_seconds: '',
                offer_description_en: '',
                offer_description_bn: '',
            },
            errors: ''
        },
        table: {
            data: '',
            paginator: '',
            loading: false,
            empty: true,
            sort: {
                column: '',
                table: '',
                order: '',
            }
        },
        client_list: '',
        company_list: '',
        client_kam_list: '',
        business_kam_list: '',
        status_list: '',
    }

    const [formData, setFormData] = useState(formInitial)

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            form: {
                ...prev?.form,
                data: {
                    ...prev?.form?.data,
                    [e.target.name]: e.target.value
                }
            }
        }))
    }

    const getTableData = async (e, filteredData = { ...formData?.filter, page: 1 }, sort = null) => {
        setFormData((prev) => ({ ...prev, table: { ...prev?.table, sort, data: null, paginator: null, loading: true, empty: true } }))
        if (e && e.preventDefault) e.preventDefault();
        const transformedSort = transformSortObject(sort);
        const request = { ...filteredData, page: filteredData?.page, ...transformedSort }
        const response = await getCall(OFFER_P, request, props.user.access_token)
        if (response?.code === 200) {
            let empty = (response?.data?.data?.length == 0) ? true : false
            setFormData((prev) => ({ ...prev, table: { ...prev.table, data: response?.data?.data, paginator: response?.data?.paginator, loading: false, empty } }))
        } else {
            toast.error(response?.message?.[0])
            setFormData((prev) => ({ ...prev, table: { ...prev.table, data: null, paginator: null, loading: false, empty: false } }))
        }
    }

    useEffect(() => {
        permissionsResets(props)
        props.setPageBreadcrumb(breadcrumb)

        getTableData()
        getFilter()
    }, [])


    const handleSubmit = async (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        const request = { ...formData?.form?.data, id: formData?.form?.data?.id }
        let response;
        if (formData?.form?.data?.id) {
            response = await postCall(UPDATE_OFFER, request, props.user.access_token)
        }
        else {
            response = await postCall(CREATE_OFFER, request, props.user.access_token)
        }
        if (response?.code === 200) {
            getTableData(null, { ...formData?.filter, page: formData?.table?.paginator?.current_page })
            setFormData((prev) => ({ ...prev, form: formInitial?.form }))
            toast.success(response?.message?.[0])
            closeFormModal()
        } else {
            toast.error(response?.message?.[0])
        }
    }

    const updateModalProcess = async (id) => {
        const response = await getCall(`${SINGLE_OFFER_INFO}/${id}`, {}, props.user.access_token)
        if (response?.code === 200) {
            setFormData((prev) => ({ ...prev, form: { ...prev?.form, data: { ...response?.data, offer_title_en_selected_option: { label: response?.data?.offer_title_en, value: response?.data?.offer_title_en }, offer_title_bn_selected_option: { label: response?.data?.offer_title_bn, value: response?.data?.offer_title_bn }, offer_type_selected_option: { label: response?.data?.offer_type?.type_en, value: response?.data?.offer_type_id },operator_selected_option: { label: response?.data?.operator?.name, value: response?.data?.operator_id }, connection_type_selected_option: { label: response?.data?.connection_type?.type, value: response?.data?.connection_type_id }, amount_selected_option: { label: response?.data?.amount, value: response?.data?.amount }, offer_validity_en_selected_option: { label: response?.data?.offer_validity_en, value: response?.data?.offer_validity_en }, offer_validity_bn_selected_option: { label: response?.data?.offer_validity_bn, value: response?.data?.offer_validity_bn }, status_selected_option: { label: response?.data?.status == 1 ? 'Active' : 'Inactive', value: response?.data?.status }, offer_start_formatted: YMDHMSToJsDate(response?.data?.offer_start), offer_expire_formatted: YMDHMSToJsDate(response?.data?.offer_expire) } } }))
        }
    }


    const closeFormModal = () => {
        let modalclosebtn = document.getElementById('modalclosebtn')
        modalclosebtn.click();
    }

    const formClear = () => {
        setFormData((prev) => ({ ...prev, form: { ...formInitial?.form } }))
    }

    const filterClear = async () => {
        setFormData((prev) => ({ ...prev, filter: { ...formInitial?.filter } }))
        getTableData(null, { ...formInitial?.filter })
    }

    // filter process
    const getFilter = async () => {
        let response = await getCall(OFFER_FILTER_LIST, {}, props.user.access_token)
        if (response?.code === 200) {
            setFormData((prev) => ({ ...prev, client_list: response?.data?.filter?.client, offer_title_en_list: response?.data?.filter?.offer_title_en, offer_title_bn_list: response?.data?.filter?.offer_title_bn, offer_type_list: response?.data?.filter?.offer_type, operator_list: response?.data?.filter?.operator, connection_type_list: response?.data?.filter?.connection_type, amount_list: response?.data?.filter?.amount, offer_validity_en_list: response?.data?.filter?.offer_validity_en, offer_validity_bn_list: response?.data?.filter?.offer_validity_bn, status_list: response?.data?.filter?.status }))
        } else {
        }
    }

    const handleSortChange = (column, table, order) => {
        setFormData((prev) => ({ ...prev, table: { ...prev.table, sort: { column, table, order } } }))
        getTableData(null, { ...formData?.filter, page: 1 }, { column, table, order })
    };

    return (
        <Fragment>

            <div className="card">

                <div className="card-header pt-5">
                    <form className="w-100" onSubmit={getTableData} >
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >Offer Title EN:</label>
                                <Select options={formData?.offer_title_en_list} value={formData?.filter?.offer_title_en_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, offer_title_en_selected_option: option, offer_title_en: option?.value } }))
                                    }
                                    isClearable placeholder="Select Offer Title EN" />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >Offer Title BN:</label>
                                <Select options={formData?.offer_title_bn_list} value={formData?.filter?.offer_title_bn_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, offer_title_bn_selected_option: option, offer_title_bn: option?.value } }))
                                    }
                                    isClearable placeholder="Select Offer Title BN" />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >Offer Type:</label>
                                <Select options={formData?.offer_type_list} value={formData?.filter?.offer_type_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, offer_type_selected_option: option, offer_type_id: option?.value } }))
                                    }
                                    isClearable placeholder="Select Offer Type" />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >Operator:</label>
                                <Select options={formData?.operator_list} value={formData?.filter?.operator_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, operator_selected_option: option, operator_id: option?.value } }))
                                    }
                                    isClearable placeholder="Select Operator" />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >Connection Type:</label>
                                <Select options={formData?.connection_type_list} value={formData?.filter?.connection_type_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, connection_type_selected_option: option, connection_type_id: option?.value } }))
                                    }
                                    isClearable placeholder="Select Connection Type" />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >Amount:</label>
                                <Select options={formData?.amount_list} value={formData?.filter?.amount_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, amount_selected_option: option, amount: option?.value } }))
                                    }
                                    isClearable placeholder="Select Amount" />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >Offer Validity EN:</label>
                                <Select options={formData?.offer_validity_en_list} value={formData?.filter?.offer_validity_en_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, offer_validity_en_selected_option: option, offer_validity_en: option?.value } }))
                                    }
                                    isClearable placeholder="Select Offer Validity EN" />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >Offer Validity BN:</label>
                                <Select options={formData?.offer_validity_bn_list} value={formData?.filter?.offer_validity_bn_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, offer_validity_bn_selected_option: option, offer_validity_bn: option?.value } }))
                                    }
                                    isClearable placeholder="Select Offer Validity BN" />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <label className="control-label col-md-12" >Status:</label>
                                <Select options={formData?.status_list} value={formData?.filter?.status_selected_option}
                                    onChange={(option) =>
                                        setFormData((prev) => ({ ...prev, filter: { ...formData?.filter, status_selected_option: option, status: option?.value } }))
                                    }
                                    isClearable placeholder="Select Status" />
                            </div>


                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5 my-auto">
                                <Submitbutton />

                                <button type="button" className="btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger" onClick={filterClear}>Reset</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="card-body  py-5 px-2">
                    {
                        props.permissions.includes('designation create') ?
                            <a className="btn btn-sm btn-primary ms-1 mb-2" data-bs-toggle="modal" data-bs-target="#saveConfirmationModal" href="#0" onClick={formClear}>
                                <i className="icofont icofont-ui-add me-1"></i>
                                Create New Offer
                            </a>
                            :
                            null
                    }

                    {
                        formData?.table?.loading || formData?.table?.empty ?
                            <div className="row col-12" style={{ marginTop: "20px" }}>
                                {
                                    formData?.table?.loading ?
                                        <div className="spinner-border text-primary mx-auto" style={{ width: "70px", height: "70px" }} alt="Loading..." ></div>
                                        : null
                                }
                                {
                                    !formData?.table?.loading && formData?.table?.empty ?
                                        <div style={{ display: "flex",  justifyContent: "center", alignItems: "center" }}>
                                            <span className="badge badge-light-danger badge-lg" >No Data Found!</span>
                                        </div>
                                        : null
                                }
                            </div> : null
                    }


                    {
                        formData?.table?.data?.length > 0 ?
                            <Fragment>

                                <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                                    <thead>
                                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                            <th>Serial</th>
                                            <th>Offer Title <Sorting column="offer_title_en" order={formData?.table?.sort?.column == 'offer_title_en' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Offer Type <Sorting table="offer_type" column="offer_type.type_en" order={formData?.table?.sort?.column == 'offer_type.type_en' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Operator <Sorting table="operator" column="operator.name" order={formData?.table?.sort?.column == 'operator.name' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Connection Type <Sorting table="connection_type" column="connection_type.type" order={formData?.table?.sort?.column == 'connection_type.type' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Amount <Sorting column="amount" order={formData?.table?.sort?.column == 'amount' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Offer Validity <Sorting column="offer_validity_en" order={formData?.table?.sort?.column == 'offer_validity_en' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Offer Start <Sorting column="offer_start" order={formData?.table?.sort?.column == 'offer_start' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Offer Expire <Sorting column="offer_expire" order={formData?.table?.sort?.column == 'offer_expire' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Status <Sorting column="status" order={formData?.table?.sort?.column == 'status' ? formData?.table?.sort?.order : null} onSortChange={handleSortChange} /></th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            formData?.table?.data?.map((row, i) => {
                                                return (
                                                    <tr key={'table-row-' + i}>
                                                        <td>{formData?.table?.paginator?.current_page > 1 ? ((formData?.table?.paginator?.current_page - 1) * formData?.table?.paginator?.record_per_page) + i + 1 : i + 1}</td>
                                                        <td>{row?.offer_title_en}</td>
                                                        <td>{row?.offer_type?.type_en}</td>
                                                        <td>{row?.operator?.name}</td>
                                                        <td>{row?.connection_type?.type}</td>
                                                        <td>{row?.amount}</td>
                                                        <td>{row?.offer_validity_en}</td>
                                                        <td>{row?.offer_start}</td>
                                                        <td>{row?.offer_expire}</td>
                                                        <td><Badge badgeClassName={row?.status == 1 ? 'badge-light-success' : 'badge-light-danger'} badgeValue={row?.status == 1 ? 'Active' : 'Inactive'} /></td>

                                                        <td>
                                                            {
                                                                props.permissions.includes('offer update') ?
                                                                    <div className="form-inline" >
                                                                        <Link role="button" data-bs-toggle="modal" data-bs-target="#saveConfirmationModal" title="Edit Record?" to="0#"  className="btn btn-icon btn-active-light-primary w-30px h-30px me-3"
                                                                            onClick={() => updateModalProcess(row.id)}
                                                                        >
                                                                            <span className="svg-icon svg-icon-3"><Svgediticoncomponent /></span>
                                                                        </Link>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                            </Fragment>
                            : null
                    }
                    {
                        formData?.table?.paginator?.total_pages > 1 ?
                            <Paginate paginator={formData?.table?.paginator} pagechanged={(page) => getTableData(null, { ...formData?.filter, page })} /> : null
                    }
                </div>
            </div>


            <div className="modal fade modal-backdrop-static" id="saveConfirmationModal" tabIndex="-1" aria-labelledby="saveConfirmationModal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header  py-4">
                            <p className="modal-title text-center text-dark fw-bolder d-block fs-3" id="saveConfirmationModal" style={{ flex: "auto" }}>{formData?.id ? 'Update' : 'Create New'} Offer</p>
                            <div className="btn btn-icon btn-sm btn-active-light-danger ms-2" data-bs-dismiss="modal" aria-label="Close" onClick={formClear}>
                                <i className="icofont icofont-ui-close me-1"></i>
                            </div>
                        </div>
                        <div className="modal-body pt-0 mt-0" >
                            <form className="form-horizontal" onSubmit={handleSubmit} >
                                <div>
                                    <input type="number" className="form-control form-control-sm" id="id" name="id" value={formData?.form?.data?.id} onChange={handleChange} readOnly hidden style={{ heigh: "0", width: "0" }} />

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Offer Title EN<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control form-control-sm form-control-solid" name="offer_title_en" placeholder="Offer Title EN" value={formData?.form?.data?.offer_title_en} onChange={handleChange} required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Offer Title BN<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control form-control-sm form-control-solid" name="offer_title_bn" placeholder="Offer Title BN" value={formData?.form?.data?.offer_title_bn} onChange={handleChange}  />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Offer Type<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <Select options={formData?.offer_type_list} value={formData?.form?.data?.offer_type_selected_option}
                                                    onChange={(option) =>
                                                        setFormData((prev) => ({ ...prev, form: { ...formData?.form, data: { ...formData?.form?.data, offer_type_selected_option: option, offer_type_id: option?.value } } }))
                                                    }
                                                    isClearable placeholder="Select Offer Type" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Operator<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <Select options={formData?.operator_list} value={formData?.form?.data?.operator_selected_option}
                                                    onChange={(option) =>
                                                        setFormData((prev) => ({ ...prev, form: { ...formData?.form, data: { ...formData?.form?.data, operator_selected_option: option, operator_id: option?.value } } }))
                                                    }
                                                    isClearable placeholder="Select Operator" />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Connection Type<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <Select options={formData?.connection_type_list} value={formData?.form?.data?.connection_type_selected_option}
                                                    onChange={(option) =>
                                                        setFormData((prev) => ({ ...prev, form: { ...formData?.form, data: { ...formData?.form?.data, connection_type_selected_option: option, connection_type_id: option?.value } } }))
                                                    }
                                                    isClearable placeholder="Select Connection Type" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Amount<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <input type="number" className="form-control form-control-sm form-control-solid" name="amount" placeholder="Amount" value={formData?.form?.data?.amount} onChange={handleChange} required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Offer Validity EN<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control form-control-sm form-control-solid" name="offer_validity_en" placeholder="Offer Validity EN" value={formData?.form?.data?.offer_validity_en} onChange={handleChange} required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Offer Validity BN<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control form-control-sm form-control-solid" name="offer_validity_bn" placeholder="Offer Validity BN" value={formData?.form?.data?.offer_validity_bn} onChange={handleChange}  />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Offer Start<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <DatePicker selected={formData?.form?.data?.offer_start_formatted} onChange={(value) => {
                                                    setFormData((prev) => ({ ...prev, form: { ...formData?.form, data: { ...formData?.form?.data, offer_start_formatted: value, offer_start: jsDateToYMDHMS(value) } } }))

                                                }} dateFormat="yyyy-MM-dd HH:mm:ss" placeholderText="Offer Start" className="form-control" showTimeSelect timeFormat="HH:mm:ss" />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Offer Expire<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <DatePicker selected={formData?.form?.data?.offer_expire_formatted} onChange={(value) => {
                                                    setFormData((prev) => ({ ...prev, form: { ...formData?.form, data: { ...formData?.form?.data, offer_expire_formatted: value, offer_expire: jsDateToYMDHMS(value) } } }))

                                                }} dateFormat="yyyy-MM-dd HH:mm:ss" placeholderText="Offer Expire" className="form-control" showTimeSelect timeFormat="HH:mm:ss" />

                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Status<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <Select options={formData?.status_list} value={formData?.form?.data?.status_selected_option}
                                                    onChange={(option) =>
                                                        setFormData((prev) => ({ ...prev, form: { ...formData?.form, data: { ...formData?.form?.data, status_selected_option: option, status: option?.value } } }))
                                                    }
                                                    isClearable placeholder="Select Status" />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Offer URL<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control form-control-sm form-control-solid" name="offer_url" placeholder="Offer URL" value={formData?.form?.data?.offer_url} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Offer Validity Seconds<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <input type="number" className="form-control form-control-sm form-control-solid" name="offer_validity_seconds" placeholder="Offer Validity Seconds" value={formData?.form?.data?.offer_validity_seconds} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Offer Description EN<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <textarea type="text" className="form-control form-control-sm form-control-solid" name="offer_description_en" placeholder="Offer Description EN" value={formData?.form?.data?.offer_description_en} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-12 my-2">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label control-label">Offer Description BN<Validation.RequiredStar /></label>
                                            <div className="col-sm-8">
                                                <textarea type="text" className="form-control form-control-sm form-control-solid" name="offer_description_bn" placeholder="Offer Description BN" value={formData?.form?.data?.offer_description_bn} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>



                                    <div className="modal-footer pb-0">
                                        <button type="button" className="btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger" data-bs-dismiss="modal" onClick={formClear} id="modalclosebtn">Cancel</button>
                                        <button type="submit" className="btn btn-sm btn-primary" id="formSubmit">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}



const mapStateToProps = (state) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions
});

const mapDispatchToProps = (dispatch) => ({
    setPageBreadcrumb: (data) => dispatch(SET_BREADCRUMB_DATA(data)),
    me: (data) => dispatch(SET_USER_DATA(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Offer));

