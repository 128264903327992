import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { SET_BREADCRUMB_DATA, SET_USER_DATA } from '../redux/action'
import { permissionsResets, createAuditLog, userAgent } from '../components/Helpers/CommonHelpers'
import { Link } from 'react-router-dom';
import "./dashboard.css"

function Dashboard(props) {
    const breadcrumb = {
        pageTitle: 'Dashboard',
        currentPath: '/',
        layers: [
            {
                title: 'Home',
                link: '/',
            }
        ],

    }
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props?.user?.force_password != 0) {
            props.history.push('/profile')
        }
        permissionsResets(props)
        props.setPageBreadcrumb(breadcrumb)
        createAuditLog(props, { hit_map: 'page', page: breadcrumb?.pageTitle, page_url: window.location.href, user_agent: userAgent })
    }, [])




    return (
        <React.Fragment>


            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-3 mb-4">
                        <div className="card dash-card1">
                            <div className="card-body">
                                <h5 className="card-title"><Link to="/offer" style={{ color: "#ffffffde" }}>Offer Management</Link></h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div className="card dash-card2">
                            <div className="card-body">
                                <h5 className="card-title"><Link to="/view-offers" style={{ color: "#ffffffde" }}>View Offers: API Documentation</Link></h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div className="card dash-card3">
                            <div className="card-body">
                                <h5 className="card-title"><Link to="/client" style={{ color: "#ffffffde" }}>Client Onboard</Link></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}


const mapStateToProps = (state) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions
});

const mapDispatchToProps = (dispatch) => ({
    setPageBreadcrumb: (data) => dispatch(SET_BREADCRUMB_DATA(data)),
    me: (data) => dispatch(SET_USER_DATA(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
