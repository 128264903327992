import { ME, CREATE_AUDIT_LOG } from '../../api/apiPath'
import { postCall } from '../../api/apiService'

const moment = require('moment');
const CryptoJS = require("crypto-js");




export const getCurrentYear = () => {
    return (new Date().getFullYear());
}

export const getCurrentTime = () => {
    var today = new Date();
    return today.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
}




export const timeCalculator = (time) => {
    var [hour, min, seconds] = time.split(":")

    var ampm = 'am'
    var finalResult = ''

    if (time == '00:00:00') finalResult = ['12:00 am'] // midnight
    else if (time == '12:00:00') finalResult = ['12:00 pm'] // midnight
    else {
        if (parseInt(hour) > 12) { hour = (parseInt(hour) % 12).toString(); ampm = 'pm' }
        else if (parseInt(hour) > 0) { hour = hour.toString(); ampm = 'am' }
        finalResult = hour.padStart(2, '0') + ':' + min.padStart(2, '0') + ' ' + ampm
    }

    return finalResult;
}




export const getNumbersInArray = (start = 0, end = 0, gap = 1, targetLength = 0, padString = '0') => {
    var finalResult = []
    for (let i = start; i <= end; i = i + gap) {
        finalResult[i] = i.toString().padStart(targetLength, padString)
    }
    return finalResult;
}

export const getWeekFullDays = () => {
    var finalResult = ['saturday', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday']
    return finalResult;
}

export const timeGenerator = (hour = 0, min = 0, ampm = 'am') => {
    var finalResult = ''
    if (parseInt(hour) == 12 && parseInt(min) == 0 && ampm == 'am') finalResult = '00:00:00' // midnight
    else if (parseInt(hour) == 12 && parseInt(min) == 0 && ampm == 'pm') finalResult = '12:00:00' // midnight
    else {
        if (parseInt(hour) == 12 && parseInt(min) >= 0 && ampm == 'am') hour = '00' // midnight
        else if (parseInt(hour) == 12 && parseInt(min) >= 0 && ampm == 'pm') hour = '12'  // noon
        else if (ampm == 'pm') hour = (12 + parseInt(hour)).toString()  // after 12 pm
        finalResult = hour + ':' + min + ':00'
    }
    return finalResult;
}

export const timeSplit = (time = '00:00:00') => {
    var [hour, min, seconds] = time.split(":")

    var ampm = 'am'
    var finalResult = ''

    if (time == '00:00:00') finalResult = ['12', '00', 'am'] // midnight
    else if (time == '12:00:00') finalResult = ['12', '00', 'pm'] // midnight
    else {
        if (parseInt(hour) > 12) { hour = (parseInt(hour) % 12).toString(); ampm = 'pm' }
        else if (parseInt(hour) > 0) { hour = hour.toString(); ampm = 'am' }
        finalResult = [hour.padStart(2, '0'), min.padStart(2, '0'), ampm]
    }

    return finalResult;
}


export const refreshPage = () => {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if (reloadCount < 1) {
        sessionStorage.setItem('reloadCount', String(reloadCount + 1));
        window.location.reload();
    } else {
        sessionStorage.removeItem('reloadCount');
    }
}


// Date related

export const Last30DaysFirstDate = () => {
    return moment().subtract(1, 'months').format('YYYY-MM-DD');
}

export const LastMonthFirstDate = () => {
    return moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
}

export const LastMonthLastDate = () => {
    return moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
}

export const LastMonthName = () => {
    return moment().subtract(1, 'months').format('MMMM');
}

export const getTodayStartTime = () => {
    return moment().startOf('day').format('yy-MM-DD HH:mm:ss')
}

export const getTodayEndTime = () => {
    return moment().endOf('day').format('yy-MM-DD HH:mm:ss')
}

export const getSpecificDateTimeAMPM = (datetime) => {
    return moment(datetime).format('yy-MM-DD hh:mm A')
}


// Encryption Related

export const encrypted = (string = '', pass = 'my-secret-key@123########################%%%^^$##$%%^^^') => {

    let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(string), pass).toString()
    let enc = encodeURIComponent(ciphertext.toString())

    return enc;
}

export const decrypted = (str = '', pass = 'my-secret-key@123########################%%%^^$##$%%^^^') => {
    const decodedStr = decodeURIComponent(str);
    let decrypted = JSON.parse(CryptoJS.AES.decrypt(decodedStr, pass).toString(CryptoJS.enc.Utf8));

    console.log('decodedStr', decodedStr);
    console.log('decrypted', decrypted);
    return decrypted;
}


// Browser Related

export const paramsProcess = (string = '') => {
    let url = window.location.href
    let searchUrl = url.split('?')?.[1]
    const urlParams = new URLSearchParams(searchUrl);
    const params = Object.fromEntries(urlParams);

    return params || {}

}

export const permission_routes = [
    { pathname: '/users', permission: 'user list' },
    { pathname: '/roles', permission: 'role list' },
    { pathname: '/permissions', permission: 'permission list' },
    { pathname: '/audit-log', permission: 'audit log list' },

    { pathname: '/designation', permission: 'designation list' },
    { pathname: '/business-kam', permission: 'business kam list' },
    { pathname: '/client-kam', permission: 'client kam list' },
    { pathname: '/company', permission: 'company list' },

    { pathname: '/client', permission: 'client list' },
    { pathname: '/offer', permission: 'offer list' },
]

export const checkPermissionsWiseRouteChecker = (props) => {

    const current_pathname = props?.location?.pathname || ''

    let permission_route = permission_routes?.find((item) => {
        return item?.pathname == current_pathname
    })

    if (!(props?.permissions?.includes(permission_route?.permission))) {
        props.history.push('/')
    }
}

// every 1 minutes it hit api to check if the user is really authenticated
export const permissionsResets = async (props, options = { checkPermissionsWiseRouteChecker: true }) => {
    var response = await postCall(ME, {}, props?.user?.access_token)
    if (response?.code === 200) {
        props.me(response?.data)
    }

    if (options?.checkPermissionsWiseRouteChecker !== false) {
        checkPermissionsWiseRouteChecker(props)
    }
}


// log generating common function
// user_id int(11) 
// log_type_id int(11) 
// hit_map varchar(200) 
// page varchar(200) 
// page_url varchar(200) 
// api_path text 
// api_request longtext 
// api_response longtext 
// user_agent varchar(200) 
export const createAuditLog = async (props, formData) => {
    var response = await postCall(CREATE_AUDIT_LOG, { ...formData, user_id: props?.user?.userId }, props?.user?.access_token)
    if (response?.code === 200) {
        console.log('Log generated!');
    }
    else {
        console.error('Log generation failed!');
    }
}
// usage
// import { permissionsResets, createAuditLog, getCrrentUrlFull } from '../components/Helpers/CommonHelpers'
// createAuditLog(props, {log_type_id:2, hit_map:'page', page:breadcrumb?.pageTitle, page_url:getCrrentUrlFull()})


export const userAgent = navigator.userAgent;

export const json_formatter = (str = '') => {

    str = JSON.stringify(str, null, 4)

    return str
};



export const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
}


export const isPasswordColumn = (column_name = '') => {
    let condition = 0
    if (column_name.length > 0) {
        condition = column_name.match("password") ? 1 : 0
    }
    return condition
}


export const badge_colors = ['badge-light-primary', 'badge-light-info', 'badge-light-success', 'badge-light-danger', 'badge-light-warning',]



export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const numberRegulations = (number, moneyformatbd = false, decimals = 0, emptyStr = '-', dollarSign = '', dollarSignAlign = 'left') => {
    let final_str = '';
    let negative_check = 0;
    let null_check = number ? 0 : 1;
    let zero_check = number == 0 ? 1 : 0;
    let numeric_check = (typeof number === 'number' && isFinite(number)) ? 1 : 0;

    if (number < 0) negative_check = 1;


    if (null_check) final_str = emptyStr;
    else if (!numeric_check) final_str = emptyStr;
    else if (zero_check) final_str = emptyStr;
    // else if (negative_check) {
    //     if (moneyFormatBD) {
    //         final_str = moneyFormatBD(-number);
    //     } else {
    //         final_str = -number;
    //     }
    //     final_str = '(' . final_str . ')';
    // } 
    else final_str = moneyformatbd ? moneyFormatBD(number, decimals) : number;

    if (!null_check && !zero_check && numeric_check && dollarSign) {
        if (dollarSignAlign == 'left') {
            final_str = dollarSign.final_str;
        }
        else {
            final_str = final_str.dollarSign;
        }
    }

    return final_str;
}


export const moneyFormatBD = (number, decimals = 0) => {

    var decimalNumbers = '';
    if ((number.toString()).indexOf('.') >= 0)  // if string/number has '.' , like 5.5, .56, 0.6
    {
        decimalNumbers = (number.toString()).substr((number.toString()).indexOf('.'));
        decimalNumbers = decimalNumbers.substr(1, decimals);
    }
    else {
        decimalNumbers = '';
        for (var i = 2; i <= decimals; i++) {
            decimalNumbers = decimalNumbers + '0';
        }
    }
    // return decimalNumbers;



    number = parseInt(number);
    number = number.toString();
    // // reverse
    number = reverseString(number.toString());

    var n = '';
    var stringlength = number.length;

    for (i = 0; i < stringlength; i++) {
        if (i % 2 == 0 && i != stringlength - 1 && i > 1) {
            n = n + number[i] + ',';
        }
        else {
            n = n + number[i];
        }
    }

    number = n;
    // // reverse
    number = reverseString(number);

    if (decimals != 0) { number = (number + '.' + decimalNumbers) }

    return number;
}


export const reverseString = (str) => {
    // Step 1. Use the split() method to return a new array
    var splitString = str.split(""); // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]

    // Step 2. Use the reverse() method to reverse the new created array
    var reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]

    // Step 3. Use the join() method to join all elements of the array into a string
    var joinArray = reverseArray.join(""); // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"

    //Step 4. Return the reversed string
    return joinArray; // "olleh"
}

export const numberFormat = (number, decimals = 0) => {
    let decimalNumbers = '';
    if (number.toString().includes('.')) {
        decimalNumbers = number.toString().substr(number.toString().indexOf('.') + 1, decimals);

        if (decimalNumbers.length < decimals) {
            for (let i = decimalNumbers.length; i < decimals; i++) {
                decimalNumbers += '0';
            }
        }
    } else {
        decimalNumbers = '0';
        for (let i = 2; i <= decimals; i++) {
            decimalNumbers += '0';
        }
    }

    number = parseInt(number);
    number = number.toString().split('').reverse().join('');

    let n = '';
    const stringLength = number.length;

    for (let i = 0; i < stringLength; i++) {
        if (i === 2 || (i > 2 && i % 2 === 0)) {
            n = n + number[i] + ',';
        } else {
            n = n + number[i];
        }
    }

    number = n.split('').reverse().join('');

    if (decimals !== 0) {
        number = number + '.' + decimalNumbers;
    }

    if (number[0] === ',') {
        number = number.substr(1);
    }

    if (number[1] === ',' && number[0] === '-') {
        number = number.substr(0, 1) + number.substr(2);
    }

    return number;
}

// Function to calculate total for a specific column
export const calculateTableTotalForSpecificColumn = (data, column) => {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
        total += parseFloat(data[i][column] || 0);
    }
    return total;
}

export const transformSortObject = (sort) => {
    if (typeof sort === 'undefined' || sort === null) {
        return null;
    }
    return {
        sort_column: sort.column,
        sort_table: sort.table,
        sort_order: sort.order
    };
}

export const generateRandomString = (length = 8) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export const jsDateToYMD = (datetime) => {
    if (!datetime) return null;
    return moment(datetime).format('YYYY-MM-DD');
}

export const jsDateToYMDHMS = (datetime) => {
    if (!datetime) return null;
    return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
}

// export const YMDHMSToJsDate = (ymdhmstring) => {
//     if (!ymdhmstring) return null;
//     console.log('===========================================================');

//     // Year, month, day, hour, minute, second
//     const [year, monthString, day, hour, minute, second] = ymdhmstring.split(/[:-]/);
//     console.log('year - ', year)
//     console.log('monthString - ', monthString)
//     console.log('day - ', day)
//     console.log('hour - ', hour)
//     console.log('minute - ', minute)
//     console.log('second - ', second)
  
//     // Month needs to be 0-indexed for Date constructor
//     const month = parseInt(monthString) - 1;
  
//     // Create the date object
//     const date = new Date(year, month, day, hour, minute, second);
  
//     return date;
//   }


export const YMDHMSToJsDate = (ymdhmstring) => {
    if (!ymdhmstring) return null;
    const date = new Date(ymdhmstring);
    return date;
  }