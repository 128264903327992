import { useState } from "react";

const Sorting = ({ column, table, order, onSortChange }) => {
    const handleClick = () => {
        // Toggle sorting order when clicked
        const newOrder = order == 'asc' ? 'desc' : 'asc';
        // Trigger sorting change
        onSortChange(column, table, newOrder);
    };

    const [iconClass, setIconClass] = useState('');

    return (
        <>
            <i className={['asc', 'desc'].includes(order) ? (order == 'asc' ? 'las la-sort-alpha-down' : 'las la-sort-alpha-down-alt') : 'las la-sort'}
                title="Click to sort"
                onClick={handleClick}
                style={{ 
                    cursor: 'pointer', 
                    color: (order ? '#74788d' : '#808080a8'), 
                    fontSize: (order ? '17px' : 'inherit'),
                    fontWeight: (order ? 'bold' : 'inherit')
                 }}
            />
        </>
    );
};

export default Sorting;
