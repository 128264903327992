import React, { Fragment, useEffect } from 'react';
import { HashRouter, Switch, Route,  } from 'react-router-dom';
import { connect } from 'react-redux'
import Login from '../pages/Login';
import ChangePassword from '../pages/ChangePassword';
import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import Users from '../pages/Users';
import Roles from '../pages/Roles';
import Permissions from '../pages/Permissions';
import AuditLog from '../pages/AuditLog';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Notfound from '../pages/Error/Notfound';
import AuthRoute from './AuthRoute';
import PublicRoute from './PublicRoute';
import Designation from '../pages/modules/configuration/designation/Designation';
import BusinessKAM from '../pages/modules/configuration/business_kam/BusinessKAM';
import Company from '../pages/modules/configuration/company/Company';
import ClientKAM from '../pages/modules/configuration/client_kam/ClientKAM';
import Client from '../pages/modules/client/client/Client';
import Offer from '../pages/modules/offer/offer/Offer';
import ViewOffer from '../pages/modules/offer/view-offer/ViewOffer';


function Router(props) {

    useEffect(() => {

    }, [])

 

    return (
        <Fragment>
            <HashRouter>
                {/* Un auth users */}
                {/* With out header and sidebar */}
                {/* <Switch>
                    <PublicRoute exact path='/login' component={Login} />
                    {!props.user ? <Route component={Notfound} /> : null}
                </Switch> */}

                {/* Authenticated users */}

                {
                    props?.user ? 

                    <div className="d-flex flex-column flex-root" hidden={!props.user} style={{ height: props.user? '100%': '0%'}}>
                        <div className="page d-flex flex-row flex-column-fluid">

                            {
                                props.user ?
                                    <Sidebar />
                                    : null
                            }


                            <div className=" d-flex flex-column flex-row-fluid" id="kt_wrapper">

                                {
                                    props.user ?
                                        <Header />
                                        : null
                                }


                                <div className="content d-flex flex-column flex-column-fluid mt-2 ms-desktop-73px" id="kt_content">
                                    <div id="kt_content_container" className="container-fluid">

                                        <Switch>
                                            <AuthRoute exact path='/' component={Dashboard} />
                                        
                                            {/* <AuthRoute exact path='/test' component={Test} /> */}
                                            {/* <AuthRoute exact path='/change-password' component={ChangePassword} /> */}
                                            <AuthRoute exact path='/profile' component={Profile} />
                                            <AuthRoute exact path='/users' component={Users} />
                                            <AuthRoute exact path='/roles' component={Roles} />
                                            <AuthRoute exact path='/permissions' component={Permissions} />
                                            <AuthRoute exact path='/audit-log' component={AuditLog} />
                                            <AuthRoute exact path='/designation' component={Designation} />
                                            <AuthRoute exact path='/business-kam' component={BusinessKAM} />
                                            <AuthRoute exact path='/client-kam' component={ClientKAM} />
                                            <AuthRoute exact path='/company' component={Company} />
                                            <AuthRoute exact path='/client' component={Client} />
                                            <AuthRoute exact path='/offer' component={Offer} />
                                            <AuthRoute exact path='/view-offers' component={ViewOffer} />
                                            
                                            {/* <Route component={Notfound} /> */}
                                        </Switch>

                                    </div>
                                </div>


                                {
                                    props.user ?
                                        <Footer />
                                        : null
                                }

                            </div>

                        </div>
                    </div>
                    : <Route component={Login} />
                }

            </HashRouter>
        </Fragment>
    )
}
const mapStateToProps = (state) => ({
    user: state.user,
    role: state.role,
    permissions: state.permissions
})

export default connect(mapStateToProps)(Router);