import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux'
import { SET_BREADCRUMB_DATA, SET_USER_DATA } from '../../../../redux/action.js'
import ReactJson from 'react-json-view'

function ViewOffer(props) {

    const breadcrumb = {
        pageTitle: 'View Offer API Documentation',
        currentPath: '/view-offers',
        layers: [
            {
                title: 'Dashboard',
                link: '/'
            },
            {
                title: 'Offer',
            },
            {
                title: 'View Offer API Documentation',
                default: 1
            }
        ]
    }

    useEffect(() => {
        props.setPageBreadcrumb(breadcrumb)
    }, [])

    const jsonData = {
        "status": "success",
        "code": 200,
        "data": [
            {
                "offer_title_en": "test",
                "offer_title_bn": "test",
                "amount": 10,
                "offer_validity_en": "test",
                "offer_validity_bn": "test",
                "offer_start": "2024-07-01 00:00:00",
                "offer_expire": "2025-07-01 00:00:00",
                "status": 1,
                "offer_url": "test",
                "offer_validity_seconds": 86400,
                "offer_description_en": "test",
                "offer_description_bn": "test",
                "operator": "GrameenPhone",
                "operator_short_name": "GP",
                "offer_type": "voice+internet"
            }
        ],
        "message": [
            "Offers data"
        ]
    }

    return (
        <Fragment>

            <div className="card">
                <div className="card-body  py-5 px-2">
                    <div className="card-body  py-5 px-2">
                        <h1>API Documentation: Get Offers</h1>

                        <h2>Base URL</h2>
                        <p><code>https://vrapi.wineds.com/</code></p>

                        <h2>Endpoint</h2>
                        <p>https://vrapi.wineds.com/api/v1/offer/offers</p>

                        <h2>Method</h2>
                        <p>GET</p>

                        <h2>Authentication</h2>
                        <p>This endpoint requires an API key for authentication. The key should be provided in the query parameter <code>key</code>.</p>

                        <h2>Query Parameters</h2>
                        <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                            <thead>
                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                    <th>Parameter</th>
                                    <th>Required</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><code>key</code></td>
                                    <td>Yes</td>
                                    <td>Your unique API key for accessing the offer data.</td>
                                </tr>
                                <tr>
                                    <td><code>operator_short_name</code> (optional)</td>
                                    <td>No</td>
                                    <td>Filter offers by operator short name. Valid values are:
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                                    <th>Name</th>
                                                    <th>Short Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="py-1">GrameenPhone</td>
                                                    <td className="py-1"><code>`GP`</code></td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1">Banglalink</td>
                                                    <td className="py-1"><code>`BL`</code></td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1">Robi Telecom</td>
                                                    <td className="py-1"><code>`RB`</code></td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1">Teletalk</td>
                                                    <td className="py-1"><code>`TT`</code></td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1">Airtel Bangladesh</td>
                                                    <td className="py-1"><code>`AB`</code></td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1">Skitto</td>
                                                    <td className="py-1"><code>`SK`</code></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </td>
                                </tr>
                                <tr>
                                    <td><code>offer_type</code> (optional)</td>
                                    <td>No</td>
                                    <td>Filter offers by type. Valid values are:
                                        <ul>
                                            <li><code>`SMS`</code></li>
                                            <li><code>`Rate Cutter`</code></li>
                                            <li><code>`Minute`</code></li>
                                            <li><code>`Internet`</code></li>
                                            <li><code>`Bundle`</code></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <h2>Example Request</h2>
                        <pre>
                            <code>
                                GET https://vrapi.wineds.com/api/v1/offer/offers?key=wZ4fK5JpYffrS9cK1hz2vlmDPWot4Wlk4sQZozrnvvcynONfqjLLzWBwRBzt&operator_short_name=GP&offer_type=voice
                            </code>
                        </pre>

                        <h2>Response</h2>
                        <p>The response will be a JSON object containing an array of offers. Each offer object will have properties specific to the offer data provided by your API.</p>

                        <h2>Example Response</h2>
                        <pre>
                            <ReactJson src={jsonData} collapsed={1} />
                        </pre>

                        <h2>Error Codes</h2>
                        <ul>
                            <li>422: Invalid query parameter value(s).</li>
                        </ul>

                        <h2>Additional Notes</h2>
                        <ul>
                            <li>You can omit the optional query parameters to retrieve all offers.</li>
                            <li>The response format and specific offer data properties may vary depending on your API implementation.</li>
                        </ul>

                    </div>
                </div>
            </div >


        </Fragment >
    )
}



const mapStateToProps = (state) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions
});

const mapDispatchToProps = (dispatch) => ({
    setPageBreadcrumb: (data) => dispatch(SET_BREADCRUMB_DATA(data)),
    me: (data) => dispatch(SET_USER_DATA(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ViewOffer));

