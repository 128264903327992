import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { LOGOUT } from "../api/apiPath";
import { postCall } from "../api/apiService";
import { USER_LOGOUT } from "../redux/action";
import useWindowDimensions from './Hooks/useWindowDimensions'
import { kt_aside_mobile_toggle } from './Helpers/DesignHelpers'

function HeaderUserLayout(props) {

    const { height, width } = useWindowDimensions();

    const userLogout = async (e) => {
        e.preventDefault();
        var response = await postCall(LOGOUT, null, props?.user?.access_token);
        if (response?.code === 200) {
            toast.success(response?.message?.[0]);
            props.logout();
            setTimeout(() => {
                window.location.reload();
            }, 50)
        } else {
            if (response?.message) {
                toast.error(response?.message?.[0]);
            }
            else {
                toast.error('Something went wrong');
            }
            props.logout();
            setTimeout(() => {
                window.location.reload();
            }, 50)
        }
    };



    useEffect(() => {

    })

    return (
        <Fragment>
            <div className="d-flex align-items-center overflow-auto pt-3 pt-sm-0">


                <div className="d-flex align-items-center">
                    {/*begin::Trigger*/}
                    <button
                        type="button"
                        className="btn p-0 mx-5"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-start"
                    >
                        <div className="symbol symbol-40px cursor-pointer" >
                            <img  src={`${process.env.PUBLIC_URL}/assets/media/logos/user-logo.png`} alt="user-pic" />
                        </div>
                    </button>
                    {/* {
                        width>991?
                        <span className="px-1 w-150px" 
                        >
                            <Link >{props.user.name}</Link> <br />
                            <span className="text-gray-400 fw-bold d-block fs-8">{props.roles[0]}</span>
                        </span>
                        : null
                    } */}




                    {/*end::Trigger*/}
                    {/*begin::Menu*/}
                    <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                        data-kt-menu="true"
                    >

                        <div className="menu-item px-3">
                            <Link to="/profile" className={`menu-link px-3 ${ props?.breadcrumb?.currentPath=='/profile' ? 'active':''}`}
                                onClick={() => kt_aside_mobile_toggle({fullpage: 1})}
                            >
                                
                                <span className="menu-icon">
                                    <i className="las la-user fs-5"></i>
                                </span>
                                <span className="menu-title">My Profile</span>
                            </Link>
                        </div>
                        <div className="menu-item px-3">
                            <Link to="/" onClick={userLogout} className="menu-link px-3">
                                
                                <span className="menu-icon">
                                    <i className="las la-sign-out-alt fs-5"></i>
                                </span>
                                <span className="menu-title">Log out</span>
                            </Link>
                        </div>
                        {/*end::Menu item*/}


                    </div>
                    {/*end::Menu*/}
                </div>






            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
    roles: state.roles,
    breadcrumb: state.breadcrumb,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(USER_LOGOUT()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserLayout);
